import React from 'react'
import Img from 'gatsby-image'
import BreadCrumb from 'src/components/Breadcrumb'
import { ContentHeader } from './style'
import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type HeroProps = {
  setIsModalOpenPj: Function;
  setIsModalMeiOpen: Function;
  setDataLayer: Function;
}

const Hero = ({ setIsModalOpenPj, setIsModalMeiOpen, setDataLayer }: HeroProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
    element_action: 'click button',
    element_name: 'Abrir conta MEI gratuita',
  }

  const dataLayerPj: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
    element_action: 'click button',
    element_name: 'Abrir conta PJ gratuita',
  }

  return (
    <ContentHeader id='cartao-pj' className='bg-white pt-4 pb-5 pt-lg-0 d-flex position-relative justify-content-center justify-content-md-between'>
      <div className='container pb-lg-5'>
        <div className='row align-items-center'>
          <div className='d-none d-lg-block col-12 col-md-6 offset-xl-1 pl-lg-0 order-md-last mb-2 mb-md-0 text-right '>
            <div>
              <Img
                fluid={data.herobannerCartaoDeCreditoEmpresarial.fluid} loading='eager' className='float-lg-right'
                alt='Uma mão segurando o cartão de crédito empresarial do Inter.'
              />
            </div>
          </div>
          <div className='col-12 col-md-12 col-lg-6 col-xl-5 pr-lg-0'>
            <div className='d-none d-md-block'>
              <BreadCrumb
                sectionName='Cartão de crédito empresarial Inter sem anuidade'
                type='Empresas'
                text='Cartão Empresarial'
                link='/empresas/cartao-de-credito-empresarial/'
              />
            </div>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-48 lh-xl-60 fw-600 text-grayscale--500 mt-sm-3 mt-lg-4'>
              Cartão de crédito <span className='d-lg-block'>empresarial Inter</span> sem anuidade
            </h1>
            <p className='fs-14 fs-md-18 lh-17 lh-md-22 text-grayscale--500 mt-4'>
              Conheça o Cartão Business do Inter e garanta o melhor para sua empresa. Com ele, você tem acesso a um cartão virtual e ao Inter Loop, o programa de pontos totalmente gratuito do Inter.
            </p>
            <div className='w-100 d-none d-lg-flex flex-column flex-md-row flex-lg-column f-lg-column align-items-center justify-content-start justify-content-md-between justify-content-lg-start mt-5 mt-sm-0 mt-md-5'>
              <div className='w-100'>
                <button
                  data-home-pj='pj'
                  title='Abrir conta PJ gratuita'
                  onClick={() => {
                    setIsModalOpenPj(true)
                    sendDatalayerEvent(dataLayerPj)
                    setDataLayer(dataLayerPj)
                  }}
                  className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'
                >
                  Abrir conta PJ gratuita
                </button>
              </div>
              <div className='w-100'>
                <button
                  onClick={() => {
                    setIsModalMeiOpen(true)
                    sendDatalayerEvent(dataLayer)
                    setDataLayer(dataLayer)
                  }}
                  data-home-mei='hero'
                  title='Abrir conta MEI gratuita'
                  className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mt-4 mt-md-0 mt-lg-3'
                >
                  Abrir conta MEI gratuita
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='row d-lg-none mt-sm-4'>
          <div className='col-12 col-md-6'>
            <div className='w-100'>
              <a
                href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
                data-home-pj='pj'
                className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
                    element_action: 'click button',
                    element_name: 'Abrir conta PJ gratuita',
                    redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
                  })
                }}
              >
                Abrir conta PJ gratuita
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='w-100'>
              <a
                href='https://intergo.app/1c4b6a55'
                data-home-pj='mei'
                className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mt-3 mt-md-0 mt-lg-3'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
                    element_action: 'click button',
                    element_name: 'Abrir conta MEI gratuita',
                    redirect_url: 'https://intergo.app/1c4b6a55',
                  })
                }}
              >
                Abrir conta MEI gratuita
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
