import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, green, white } from 'src/styles/colors'

export const CartaoMaisProtegidoEmpresarialSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.tablet} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  h2, p {
    margin-bottom: 32px;
  }
`
export const PlansContent = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const Plan = styled.div`
  width: 100%;

  h3 {
    color: #BFDEDD;
  }
  .light-pj {
    color: #07605B;
    font-weight: 700;
  }
`

export const Header = styled.div`
  padding: 16px;
  background-color: ${green.extralight};
  border-radius: 12px 12px 0 0;
`

export const Content = styled.div`
  background-color: ${white};
  border-radius: 0 0 12px 12px;
  padding: 24px 8px;

  @media ${device.desktopLG} {
    padding: 24px 32px;
  }

  @media ${device.desktopXL} {
    padding: 24px 64px;
  }

  p {
    margin-bottom: 24px;
    &:after {
      content: "";
      width: 50%;
      margin: 0 auto;
      display: block;
      padding-top: 16px;
      border-bottom: 2px solid ${grayscale[200]};
    }
  }

  p:last-child {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  }
`
