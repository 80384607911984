import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import OpenAccountPJForm from 'src/components/UI/Forms/OpenAccountPJForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Wrapper } from './style'
import pageContext from './pageContext.json'
import Hero from './sections/hero/_index'
import CashBack from './sections/cashback/_index'
import MEICreditCard from './sections/meiCreditCard/_index'
import DebitAndCreditFunctionSection from './sections/debitCreditFunction/_index'
import CreditOnTime from './sections/creditOnTime/_index'
import PracticalitySection from './sections/practicality/_intex'
import DigitalPayment from './sections/digitalPayment/_index'
import DigitalWallet from './sections/digitalWallet/_index'
import CartaoMaisProtegidoEmpresarial from './sections/cartaoMaisProtegidoEmpresarial/_index'
import BenefitsSection from './sections/benefits/_index'
import FaqSection from './sections/faq/_index'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import BaixeOAppFormMei from './assets/images/baixe-o-app-form-mei.jpg'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const ContaDigitalPJ = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isModalMeiOpen, setIsModalMeiOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Cartão de crédito empresarial Inter sem anuidade',
    element_action: 'click button',
    element_name: 'Abrir conta MEI gratuita',
  })

  const domReady = useDomReady()

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountPJForm closeModal={() => setIsModalOpen(false)} dataLayer={dataLayer} />
    </Modal>
  )

  const openAccountMeiFormModal = domReady && (
    <Modal isModalOpen={isModalMeiOpen} setIsModalOpen={setIsModalMeiOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalMeiOpen(false)}
        qrBaixeApp={BaixeOAppFormMei}
        formName='Formulario abertura de conta MEI'
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout isPJ pageContext={pageContext}>
        {openAccountPJFormModal}
        {openAccountMeiFormModal}
        <Hero
          setIsModalOpenPj={setIsModalOpen}
          setIsModalMeiOpen={setIsModalMeiOpen}
          setDataLayer={setDataLayer}
        />
        <CashBack />
        <DebitAndCreditFunctionSection />
        <CartaoMaisProtegidoEmpresarial />
        <MEICreditCard setIsModalMeiOpen={setIsModalMeiOpen} setDataLayer={setDataLayer} />
        <CreditOnTime
          setIsModalOpenPj={setIsModalOpen}
          setIsModalMeiOpen={setIsModalMeiOpen}
          setDataLayer={setDataLayer}
        />
        <PracticalitySection />
        <DigitalPayment />
        <DigitalWallet />
        <BenefitsSection />
        <FaqSection />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ
