import React from 'react'
import * as S from './style'
import CartaoMaisProtegido from './assets/data.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import slugify from 'slugify'

interface ICartaoMaisProtegido {
  category: string;
  price: string;
  beneficts: string[];
}

const CartaoMaisProtegidoEmpresarial = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const plans: ICartaoMaisProtegido[] = CartaoMaisProtegido

  return (
    <S.CartaoMaisProtegidoEmpresarialSection
      id='cartao-mais-protegido-empresarial'
      className='bg-green--extra-dark'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-white text-md-center'>
                Cartão Mais Protegido Empresarial:
                <span className='d-md-block'>Proteção extra para a sua conta</span>
              </h2>
            </div>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-20 text-white text-md-center font-sora'>
              O <span className='fw-700'>seguro Cartão Mais Protegido</span> para empresas vem para proteger sua conta PJ de prejuízos financeiros causados pela perda, roubo e furto do cartão, bem como de operações de saques e compras por coação.
            </p>
            <S.PlansContent>
              {plans.map((plan: ICartaoMaisProtegido) => (
                <S.Plan key={plan.category}>
                  <S.Header>
                    <h3 className='fs-16 lh-20 fw-600 font-sora text-center mb-1'>{plan.category}</h3>
                    <p className='fs-20 lh-22 fw-600 text-grayscale--100 font-sora text-center mb-0'>{plan.price}</p>
                  </S.Header>
                  <S.Content>
                    <div className='text-center'>
                      {plan.beneficts.map((text: string) => (
                        <p key={slugify(text)} className='fs-16 lh-20 text-grayscale--500' dangerouslySetInnerHTML={{ __html: text }} />
                    ))}
                    </div>
                  </S.Content>
                </S.Plan>
              ))}
            </S.PlansContent>
          </div>
          <div className='col-12 col-md-6 mt-4'>
            <a
              href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
              target='_blank'
              rel='noreferrer'
              className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700 mw-100'
              onClick={() =>
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name:
                    'Cartão de crédito para sua empresa com cashback',
                  element_action: 'click button',
                  element_name: 'Contrate agora cliente MEI',
                  redirect_url:
                    'https://inter.co/empresas/programa-de-pontos-para-empresas/',
                })
              }
            >
              Contrate agora cliente MEI
            </a>
          </div>
          <div className='col-12 col-md-6 mt-4'>
            <a
              href='https://inter.co/empresas/programa-de-pontos-para-empresas/'
              target='_blank'
              rel='noreferrer'
              className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700 mw-100'
              onClick={() =>
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name:
                    'Cartão de crédito para sua empresa com cashback',
                  element_action: 'click button',
                  element_name: 'Contrate agora cliente PJ',
                  redirect_url:
                    'https://inter.co/empresas/programa-de-pontos-para-empresas/',
                })
              }
            >
              Contrate agora cliente PJ
            </a>
          </div>
        </div>
      </div>
    </S.CartaoMaisProtegidoEmpresarialSection>
  )
}

export default CartaoMaisProtegidoEmpresarial
